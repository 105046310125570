import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import { useMemo } from "react";
import { useParams } from "react-router";

export default function useCurrentLocation(): any {
  const { data: locations, isLoading: loading } = useListLocationsQuery();
  const { locationSlug } = useParams<{ locationSlug: string }>();

  const currentLocation = useMemo(() => {
    if (locations) {
      return locations.find((location: any) => location.slug === locationSlug);
    }
    return {};
  }, [locations, locationSlug]);

  return { currentLocation, loading };
}
